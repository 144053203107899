import revive_payload_client_4sVQNw7RlN from "E:/www/shop_nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "E:/www/shop_nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "E:/www/shop_nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "E:/www/shop_nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "E:/www/shop_nuxt/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "E:/www/shop_nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "E:/www/shop_nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "E:/www/shop_nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "E:/www/shop_nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "E:/www/shop_nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "E:/www/shop_nuxt/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "E:/www/shop_nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "E:/www/shop_nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import lazyloading_XZyllNtwGn from "E:/www/shop_nuxt/plugins/lazyloading.js";
import lazyloading_Swpf5jVcVD from "E:/www/shop_nuxt/plugins/lazyloading.ts";
import owayomenu_zvcj7FOC9H from "E:/www/shop_nuxt/plugins/owayomenu.js";
import owayomenu_PwdD6IQOkr from "E:/www/shop_nuxt/plugins/owayomenu.ts";
import store_pBBVV6Lb3L from "E:/www/shop_nuxt/plugins/store.js";
import store_9xNuDHGAVU from "E:/www/shop_nuxt/plugins/store.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  lazyloading_XZyllNtwGn,
  lazyloading_Swpf5jVcVD,
  owayomenu_zvcj7FOC9H,
  owayomenu_PwdD6IQOkr,
  store_pBBVV6Lb3L,
  store_9xNuDHGAVU
]