<template>
  <footer class="team-footer">
    <nav class="team-footer--navi">
      <ul class="block-list">
        <li class="block-list-elem"><a :href="datenschutzLink" target="_blank">{{ $t("datenschutz") }}</a></li>
        <li class="block-list-elem">
          <NuxtLink :to="getRouteWithprefix('impressum')" :isOwner="isOwner">
            <span>{{ $t("Impressum") }}</span>
          </NuxtLink>
        </li>
        <li class="block-list-elem"><a :href="AGBLink" target="_blank">{{ $t("agbsHauptnaviTitle") }}</a></li>
        <li class="block-list-elem"><a :href="faqLink" target="_blank">{{ $t("konf_Help") }}</a></li>
        <li class="block-list-elem"><a :href="versandLink" target="_blank">{{ $t("shop_versand") }}</a></li>
      </ul>
    </nav>
    <div class="team-footer--info">
      {{ $t("shop_productionByowayo") }}<br/>
      shop@owayo.com<br/>
      {{ $t("telefonNummer_0800_890_5500") }}
    </div>
    <div class="team-footer--meta">
      <div class="team-footer--icons-payment">
        <ul class="block-list">
          <li class="block-list-elem"><img src="~/assets/paypal-logo.svg" alt="paypal"></li>
          <li class="block-list-elem"><img src="~/assets/visa.png" alt="visa"></li>
          <li class="block-list-elem"><img src="~/assets/mastercard.svg" alt="mastercard"></li>
        </ul>
      </div>
      <div class="team-footer--owayo-info">
        <a :href="owayoURL" target="_blank">{{ $t("shop_owayoTeamshop") }}</a>
      </div>
    </div>
  </footer>
</template>

<script>
import {getRouteWithprefix} from "~/_router/routeUtils";
import {LinksToWebsiteShop, useFooterStore} from "~/store/FooterStore";
import {useShopInfos} from "~/store/shopInfosStore";
import {useRequestURL} from "#app";

export default {
  name: "ShopFooter",
  props: {
    isOwner: Boolean
  },
  setup() {
    const store = useShopInfos();
    // const heightBtn = ref(null);
    const footerstore = useFooterStore();
    const url = useRequestURL();

    footerstore.INIT_FOOTER_STORE({sprache: store.getShopSprache, land: store.getShopLand, domain: url.hostname});
    // store.dispatch("loadInternationalLinks");

    return {
      datenschutzLink: computed(() => footerstore.getLinkToWebsite(LinksToWebsiteShop.privacyterms)),
      AGBLink: computed(() => footerstore.getLinkToWebsite(LinksToWebsiteShop.agbs)),
      versandLink: computed(() => footerstore.getLinkToWebsite(LinksToWebsiteShop.lieferzeit)),
      faqLink: computed(() => footerstore.getLinkToWebsite(LinksToWebsiteShop.faq)),
      owayoURL: computed(() => store.getOwayoURL),
      getRouteWithprefix
    }
  }
}
</script>

<style lang="scss" scoped>
.team-footer--navi {
  .block-list {
    display: flex;
    flex-wrap: wrap;

    .block-list-elem {
      padding-right: 80px;
      margin-bottom: 20px;

      @media (max-width: 860px) {
        padding-right: 40px;
      }

      @media (max-width: 660px) {
        padding-right: 20px;
      }

      a, span {
        display: block;
        color: $color-black;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}

.team-footer--meta {
  display: flex;
  flex-direction: row;
  padding-bottom: 40px;

  .team-footer--icons-payment {
    width: 70%;

    .block-list {
      display: flex;
      flex-direction: row;

      .block-list-elem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;

        img {
          max-height: 15px;
        }
      }
    }
  }

  .team-footer--owayo-info {
    width: 30%;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;

    color: $color-blue;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;

    a {
      color: $color-blue;

      span {
        display: inline-block;
        font-weight: 600;

        &:first-child {
          text-transform: uppercase;
        }
      }
    }
  }
}


.team-footer {

  &--navi {
    border-top: 1px solid $color-grey;
    padding: 20px 20px 0 20px;
  }

  &--info {
    padding: 30px 20px 0 20px;
    font-size: 14px;
    line-height: 22px;
  }

  &--meta {
    padding: 30px 20px 0 0;
  }

}
</style>
