<template>
  <div class="shop__popup--wrapper">
    <div class="open-popup-editshop shop__popup">
      <button class="km__button--close" type="button" name="close" @click="$emit('close_popup')"></button>
      <div class="km__popup--content shop__layout">
        <label class="km__label--text" for="themeID">{{$t('konf_theme')}}</label>
        <div id="theme-upload-container">
       <input id="shopThemeUpload" class="btn-upload" name="shopThemeUpload" type="file" @change="uploadTheme($event, ()=>$emit('close_popup'))">
       <label class="km__button&#45;&#45;standard blue" for="shopThemeUpload">{{$t("themeHochladen")}}</label>
     </div>

          <div class="shop__layout--liste owayo-themes">
            <div class="shop__layout--theme"  v-for="theme in possibleOwayoThemes" :key="theme.theme">
              <PopupEditThemeOption :theme="theme" @changeSelectedTheme="setSelectedTheme"/>
            </div>
          </div>
          <div class="shop__layout--liste user-themes">
            <div class="shop__layout--theme user-theme"  v-for="theme in possibleUserThemes" :key="theme.theme">
              <PopupEditThemeOption :theme="theme" @changeSelectedTheme="setSelectedTheme"/>
            </div>
          </div>

      </div>

      <div class="km__popup--buttons">
        <button class="km__button--standard" @click="$emit('close_popup')">{{ $t("konf_Close") }}</button>
        <button class="km__button--standard blue" @click="saveShopTheme">{{ $t("konf_Save") }}</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed} from "vue";
import PopupEditThemeOption from "~/components/PopupEditThemeOption.vue";
import {useShopInfos} from "~/store/shopInfosStore";
import {useShopStore} from "@ur/components/store/UserShopStore";
import {type ShopLogoUploadResponse} from "@k/js/databaseInterfaceClasses";
import axios from "axios";

export default {
  name: "PopupEditTheme",
  components: {PopupEditThemeOption},
  setup(props, context){
    const userStore = useShopStore();
    userStore.loadIfNotAlreadyLoaded();

    let shopTheme = userStore.getUserShopTheme;
    const shopInfos = useShopInfos();
    let bgImage = shopInfos.getThemeBgImage;

    function saveShopTheme() {

      userStore.SET_USERSHOP_THEME(shopTheme)
      userStore.SET_USERSHOP_THEME_BGIMAGE(bgImage);
      shopInfos.SET_SHOP_THEME({theme: shopTheme, bgImage:bgImage});
      userStore.setUserShopStatus("");
      userStore.changeTheme({theme: shopTheme, bgImage:bgImage});
      context.emit('close_popup');
    }

    function uploadTheme(e:Event, close:()=>void) {
      const shopInfos = useShopInfos();
      const formData = new FormData();
      formData.append("file", ((e.target as HTMLInputElement).files as FileList)[0]);
      formData.append('shopname', shopInfos.shopname);
      axios.post("/shop_php/uploadShopTitelbild.php", formData, {
        headers: {'Content-Type': 'multipart/form-data'}
      }).then((response:{data:ShopLogoUploadResponse}) => {
        shopInfos.getInfos(shopInfos.shopname);
        close();
      });
    }

    return{
      saveShopTheme,
      possibleThemes: computed(()=>userStore.getPossibleThemes),
      possibleOwayoThemes:computed(()=>userStore.getPossibleThemes?.filter(t=>t.userTheme === "0") || []) ,
      possibleUserThemes:computed(()=>userStore.getPossibleThemes?.filter(t=>t.userTheme === "1") || []),
      setSelectedTheme:(data)=>{
        shopTheme = data["theme"];
        bgImage = data["image"];
      },
      uploadTheme:(e:Event, close:()=>void)=>uploadTheme(e, close)

    }
  }
}
</script>

<style lang="scss" scoped>
.open-popup-editshop {
  max-width: 1200px;

  .km__label--text {
    margin-bottom: 20px;
  }

  .shop__layout--liste {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
  }

  .shop__layout--theme {
    display: flex;
    flex-wrap: wrap;
    padding-top: 15px;
    padding-bottom: 15px;
    width: calc(25% - 20px);
    overflow: hidden;
  }
}

input {
  display: none;
}
label {
  margin:auto
}

.km__popup--buttons {
  justify-content: center;
}

#theme-upload-container {
  margin: 35px 0 30px 0;
}
</style>
