<template>
  <div class="product-header">
    <div class="product-preview">
      <div class="shop-breadcrumb">
        <NuxtLink class="breadcrumb--link" :to="getRouteToHome()">Home</NuxtLink>
      </div>
    </div>
  </div>
  <div class="notavailable-404">
    <p>
      <span class="site-404">404</span>
      <span class="not-exist">{{ $t("shop_article_not_existing") }}</span>
    </p>
  </div>
</template>

<script>

import {getRouteToHome} from "~/_router/routeUtils";

export default {
  name: "FourOFour",
  setup() {

    return {
      getRouteToHome,
    }
  }
}
</script>

<style lang="scss" scoped>
.product-header {
  display: flex;
  flex-direction: row;
  padding-bottom: 60px;
  position: relative;

  .product-preview {
    width: 65%;
    position: relative;

    @media (max-width: 940px) {
      width: 50%;
    }
  }

  @media (max-width: 740px) {
    flex-direction: column;

    .product-preview {
      width: 100%;
    }
  }
}

.notavailable-404 {
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .site-404 {
    display: block;
    font-size: 72px;
    line-height: 74px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  .not-exist {
    display: block;
    max-width: 240px;
  }
}
</style>
