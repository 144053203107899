<template>
  <div>
    <iframe src="" name="iFrameUserManagement" id="iFrameUserManagement" class="center-screen ele-hidden"></iframe>
    <header class="shop__header">
      <nav class="shop__header--navi">
        <div class="navi--container">
          <div class="shop__header--owayo">{{ shopinfo.anzeigeName }}</div>
          <!--<div class="shop__header--owayo">{{$t("shop_owayoTeamshop")}}</div>-->
          <div class="shop__header--navi--btns">
            <AdminMenu v-if="isOwner" :isOwner="isOwner"/>
            <div class="shoppingcart-component">
              <ShopMenue
                  :username="username"
                  :shoppingcart="shoppingcart"
                  :loggedin="loggedIn"
                  :shopName="shopinfo.shopName"
                  :language="language"
                  :land="land"
                  :warenkorbPositionen="anzWarenkorbPositionen"
                  @open_component_request="openComponentRequest"
              />
            </div>
          </div>
        </div>
      </nav>
      <div class="shop__header--bg"> <!--:class="shopinfo.theme"  -->
        <div class="shop__img--wrapper" :style="{ backgroundImage: 'url(' + getTitelBild + ')' }">
        </div>
        <div v-if="isOwner" class="shop__button--edit icon edit__shop--profile" @click="togglePopupEditTheme"></div>
        <div class="shop__header--logo">
          <div v-if="isOwner" class="edit__shop--logo" @click="togglePopupEditLogo">
            <div class="shop__button--edit icon image"></div>
          </div>
          <NuxtLink class="breadcrumb--link" :to="getRouteToHome()">
            <img :src="shoplogo" :alt="shopinfo.shopName"/>
          </NuxtLink>
        </div>
      </div>
    </header>
  </div>
</template>

<script lang="ts">

import {computed, type PropType} from "vue";
import { ref, reactive, watch } from 'vue';
//@ts-ignore
import {useStore} from "vuex";
//@ts-ignore
import {NavigationOpenRequestTypeFromShoppingcartMenu} from "owayo_menu";

import AdminMenu from "~/components/AdminMenu.vue";

import {PopupNames, PopupStatus} from "~/components/PopupHandler";

import {getRouteToHome} from "~/_router/routeUtils";
import {useFooterStore} from "~/store/FooterStore";
import type {ShopInfo} from "~/store/shopInfosStore";
import {Navigation_opencomponentHandler_SHOP} from "~/navigation_opencomponentHandler_SHOP";
import {insertCloudflareDownscalingIntoURL, insertCloudflareAutoformatIntoURL} from "@k/global/globalUtils";

export default {
  name: "ShopHeader",
  components: {
    AdminMenu,
  },
  props: {
    shopinfo: Object as PropType<ShopInfo>,
    isOwner: Boolean
  },
  setup(props) {
    const vuexStore = useStore();

    function togglePopupEditTheme() {
      PopupStatus.TOGGLE_POPUP(PopupNames.editTheme);
    }

    function togglePopupEditLogo() {
      PopupStatus.TOGGLE_POPUP(PopupNames.editLogo);
    }

    const footerstore = useFooterStore();


    return {
      username: computed(() => vuexStore.getters.getUsername),
      shoppingcart: computed(() => vuexStore.getters.getWarenkorbPos),
      loggedIn: computed(() => vuexStore.getters.isLoggedIn || false),
      shoplogo: computed(() => insertCloudflareDownscalingIntoURL(props.shopinfo?.shopLogoPath || "", 160, 160)),
      openComponentRequest: (component: NavigationOpenRequestTypeFromShoppingcartMenu) => Navigation_opencomponentHandler_SHOP.open_component(component),
      logout: () => Navigation_opencomponentHandler_SHOP.open_component(NavigationOpenRequestTypeFromShoppingcartMenu.logout),
      togglePopupEditTheme,
      togglePopupEditLogo,
      getRouteToHome,
      language: computed(() => footerstore.getLanguage),
      land: computed(() => footerstore.getLand),
      anzWarenkorbPositionen: computed(() => vuexStore.getters.getAnzWarenkorbPositionen),
      getTitelBild: computed(() => props.shopinfo?.themeBgImage.includes("owayo-cdn.com") ? insertCloudflareAutoformatIntoURL(props.shopinfo?.themeBgImage) : props.shopinfo?.themeBgImage.replace(".jpg", ".webp"))
    }
  }
}
</script>


<style lang="scss">

#iFrameUserManagement {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgba(black, .4);
  z-index: 9999;

  &.center-screen {
    margin: 0;
    transform: translate(0, 0);
  }
}

.shop__header--navi {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  background: white;
  height: 60px;
  border-bottom: 1px solid #f5f5f5;
}

.navi--container {
  width: $shop-content;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .shop__header--owayo {
    font-size: 14px;
    font-weight: 600;
    color: $color-black;
    display: block;
    max-width: 40%;

    @media (max-width: 550px) {
      max-width: 50%;
    }
  }

  .shop__header--navi--btns {
    display: flex;

    .shoppingcart-component {
      position: relative;
      width: 100px;
      display: flex;

      .km__button--icon.account {
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA3OCA3OCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNzggNzg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiMwMDAwMDA7c3Ryb2tlLXdpZHRoOjIuMjY3NztzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fQ0KPC9zdHlsZT4NCjxnIGlkPSJFYmVuZV8xXzAwMDAwMDExNzM4NjI5Njg0NzI2NTIxNTkwMDAwMDEyNjQ2MjIyNjg2NTMzMjc4MzkyXyI+DQo8L2c+DQo8ZyBpZD0iRWJlbmVfMl8wMDAwMDExMjU5MDIxNzQ5MDM0NzA4NjU2MDAwMDAxMzkyOTgwMDM4NTEwMzkwNjE3OV8iPg0KCTxnPg0KCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzkuNCw1MC43YzEsMCwyLjEtMC4xLDIuNS0wLjRjMS40LTAuNiw1LjctNS4xLDYuNi03LjZjMC40LTEsMC43LTIuMiwwLjctMi4yczIuNiwwLjksMy4yLDAuNQ0KCQkJYzEtMC42LDQuMS04LjUsMy40LTkuM2MtMC42LTAuNy0zLjYtMC4zLTMuNi0wLjNTNTUsMTkuMyw0OCwxNy45Yy01LjEtMS0zLjctNC42LTMuNy00LjZzLTguNS0wLjYtMTQuNSw0LjcNCgkJCWMtNi40LDUuNi00LjEsMTMuNS00LjEsMTMuNXMtMy0wLjQtMy42LDAuM2MtMC43LDAuOCwyLjQsOC43LDMuNCw5LjNjMC42LDAuNCwzLjItMC41LDMuMi0wLjVzMC40LDEuMiwwLjcsMi4yDQoJCQljMC45LDIuNSw1LjIsNyw2LjYsNy42YzAuNSwwLjIsMS41LDAuMywyLjUsMC40SDM5LjR6Ii8+DQoJCTxnPg0KCQkJPHBhdGggY2xhc3M9InN0MCIgZD0iTTEyLjMsNjQuNWMwLTEwLjMsOC42LTE1LjgsMjAtMTciLz4NCgkJCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik02NS43LDY0LjdjMC0xMC4zLTguNi0xNS44LTIwLTE3Ii8+DQoJCTwvZz4NCgk8L2c+DQo8L2c+DQo8L3N2Zz4NCg==');
        background-size: 35px;
        opacity: 1;
        right: 0;
        position: relative;
      }

      .km__button--icon.shoppingcart {
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA1NS41IDU0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1NS41IDU0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojMTExMTExO30NCjwvc3R5bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjguNiwzLjRjLTYuOCwwLTEyLjQsNS42LTEyLjQsMTIuNGMwLDAuNSwwLDEsMC4xLDEuNEgzLjZjMC44LDExLjgsMi4zLDMwLjMsMywzMS41QzYuNyw0OC44LDYuOCw0OSw3LDQ5LjENCgljMSwxLjMsMS4yLDEuNSw2LjUsMS41aDI4LjZjNS4zLDAsNS41LTAuMyw2LjUtMS41YzAuMS0wLjEsMC4yLTAuMywwLjQtMC41YzAuNy0xLjIsMi4yLTE5LjcsMy0zMS41SDQxYzAuMS0wLjUsMC4xLTAuOSwwLjEtMS40DQoJQzQxLDguOSwzNS41LDMuNCwyOC42LDMuNEwyOC42LDMuNHogTTE5LjksMTcuMmMtMC4xLTAuNS0wLjEtMC45LTAuMS0xLjRjMC00LjksNC04LjgsOC44LTguOGM0LjksMCw4LjgsNCw4LjgsOC44DQoJYzAsMC41LDAsMS0wLjEsMS40SDE5LjlMMTkuOSwxNy4yeiIvPg0KPC9zdmc+DQo=');
        background-size: 28px;
        background-color: transparent;
        opacity: 1;
        right: 0;
        position: relative;
      }
    }
  }
}

.shop__header--bg {
  top: 60px;
  position: relative;

  .shop__header--logo {
    position: absolute;
    width: 160px;
    height: 160px;
    left: 120px;
    bottom: -60px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 100%;
    border: 5px solid white;
    background: $color-stage-bg;
    padding: 15px;
    font-family: "owayoFont", Arial, Verdana, Helvetica, sans-serif;

    @media (max-width: 1100px) {
      left: 50%;
      margin-left: -80px;
    }

    .edit__shop--logo {
      position: absolute;
      right: -10px;
      bottom: 15px;
      z-index: 9;
    }

    img {
      clip-path: circle(74px at center);
    }
  }

  .edit__shop--profile {
    position: absolute;
    right: 120px;
    bottom: 20px;

    @media (max-width: 800px) {
      right: 20px;
    }
  }
}

.shop__img--wrapper {
  position: relative;
  width: 100%;
  height: 300px;
  background-color: $color-stage-bg;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


</style>

