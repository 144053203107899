<template>
  <div class="shop__popup--wrapper">
    <div class="open-popup-editshop shop__popup">
      <button class="km__button--close" type="button" name="close" @click="$emit('close_popup')"></button>
      <div class="km__popup--content shop__info">
        <label class="km__label--text" for="shopAnzeigeName">{{$t('shop_shopName')}}</label>
        <input class="km__input--standard" type="text" name="Shop" v-model="shopAnzeigeName" id="shopAnzeigeName">
        <label class="km__label--text" for="popup_shopBeschreibung">{{$t('konf_Beschreibung')}}</label>
        <textarea class="km__input--textarea moretext" type="text" name="Shop" id="popup_shopBeschreibung" v-model="produktSeite.BeschreibungContent" :placeholder="$t('shop_contentDescription')"></textarea>
        <label class="km__label--text" for="shopMetaTitle">{{$t('shop_metaTitle')}}</label>
        <input class="km__input--standard" type="text" name="Shop" v-model="produktSeite.MetaTitle" id="shopMetaTitle">
        <label class="km__label--text" for="popup_metaDesciption">{{$t('shop_metaDescription')}}</label>
        <textarea class="km__input--textarea moretext" type="text" name="Shop" id="popup_metaDesciption" :value="produktSeite.MetaDescription" :placeholder="$t('shop_metaDescription')"></textarea>
      </div>
      <div class="km__popup--buttons">
        <button class="km__button--standard" @click="$emit('close_popup')">{{ $t("konf_Close") }}</button>
        <button class="km__button--standard blue" @click="saveContentBlock">{{ $t("konf_Save") }}</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed} from "vue";
import {useShopInfos} from "~/store/shopInfosStore";
import {useShopSeiten} from "~/store/shopSeitenStore";
import {useShopStore} from "@ur/components/store/UserShopStore";

export default {
  name: "PopupEditProfil",
  props: {
    shop: Object
  },
  setup(props, context){
    const userShop = useShopStore();
    userShop.loadIfNotAlreadyLoaded();
    const shopseiten = useShopSeiten();
    const shopInfo = useShopInfos();
    const produktSeite = computed(()=>shopseiten.getProduktSeite);
    const shopInfos = useShopInfos();

    const shopName = computed({
      get(){return userShop.getUserShopname},
      set(newValue){userShop.SET_USERSHOP_NAME(newValue as string)}});

    const shopAnzeigeName = computed({
      get(){return shopInfo.getShopAnzeigename},
      set(newValue){shopInfo.SET_USERSHOP_ANZEIGENAME(newValue)}});


    function saveContentBlock(){
      shopseiten.saveProduktseitenBeschreibung({
        shopname: shopInfos.shopname,
        anzeigename: shopInfos.getShopAnzeigename,
        beschreibung:document.getElementById("popup_shopBeschreibung").value,
        metaTitle:document.getElementById("shopMetaTitle").value,
        metaDescription:document.getElementById("popup_metaDesciption").value
      });
      context.emit('close_popup');
    }

    return{
      shopName,
      shopAnzeigeName,
      produktSeite,
      saveContentBlock
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
