<template>
  <div class="popup_twobutton modal-wrapper">
    <div class="popup_twobutton km__popup small">
      <h2 class="km__popup--heading">{{ dialogState.header }}</h2>
      <div class="km__popup--content">
        <p v-html="dialogState.message"></p>
      </div>
      <div class="km__popup--buttons" :class="displayCancel ? 'twobuttons' :'onebutton'">
        <button v-if="displayCancel" class="km__button--standard cancel" @click="dialogState.callbackOnCancel">
          {{ dialogState.shouldTranslateButtons ? $t(canceltextID) : canceltextID}}
        </button>
        <button class="km__button--standard blue" @click="dialogState.callbackOnConfirm">{{ dialogState.shouldTranslateButtons ? $t(confirmTextID) : confirmTextID}}</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, ref} from "vue";

import gsap from "gsap";


const confirmTextID=ref("konf_OK");
const canceltextID = ref("konf_Cancel");


export default {
  name: "ModuleTwoButtonDialog",
  setup() {
    return {
      dialogState:ConfirmOrConfirmCancelDialog.dialogState,
      displayCancel: computed(() => {
        return ConfirmOrConfirmCancelDialog.dialogState.value.displayType === "ConfirmCancel"
      }
      ),
      confirmTextID,
      canceltextID
    }
  }
}


class ConfirmOrConfirmCancelDialog {
  public static dialogState = ref({
    header: "",
    message: "",
    callbackOnConfirm: ()=> ConfirmOrConfirmCancelDialog.animateToOpenstate(false),
    callbackOnCancel: ()=> ConfirmOrConfirmCancelDialog.animateToOpenstate(false),
    displayType: "ConfirmCancel" as "ConfirmCancel" | "Confirm",
    shouldTranslateButtons:true
  });

  protected static setDialog(payload: { header:string, message:string, onOK: (()=>void), onCancel: (()=>void), displayType?:"ConfirmCancel" | "Confirm", shouldTranslateButtons?:boolean }) {
    ConfirmOrConfirmCancelDialog.dialogState.value.header = payload.header;
    ConfirmOrConfirmCancelDialog.dialogState.value.message = payload.message;
    ConfirmOrConfirmCancelDialog.dialogState.value.callbackOnConfirm = payload.onOK;
    ConfirmOrConfirmCancelDialog.dialogState.value.callbackOnCancel = payload.onCancel;
    ConfirmOrConfirmCancelDialog.dialogState.value.displayType = payload.displayType || "ConfirmCancel"
    ConfirmOrConfirmCancelDialog.dialogState.value.shouldTranslateButtons = (payload.shouldTranslateButtons !== undefined ? payload.shouldTranslateButtons : true)
  }

  protected static clearAndClose() {
    ConfirmOrConfirmCancelDialog.animateToOpenstate(false);
    ConfirmOrConfirmCancelDialog.dialogState.value.header = "";
    ConfirmOrConfirmCancelDialog.dialogState.value.message = "";
    ConfirmOrConfirmCancelDialog.dialogState.value.callbackOnConfirm = ()=> ConfirmOrConfirmCancelDialog.animateToOpenstate(false);
    ConfirmOrConfirmCancelDialog.dialogState.value.callbackOnCancel = ()=> ConfirmOrConfirmCancelDialog.animateToOpenstate(false);
    ConfirmOrConfirmCancelDialog.dialogState.value.displayType = "ConfirmCancel"
  }


  public static displayOKOKCancelDialog(header:string, message:string, displayType:"ConfirmCancel" | "Confirm", shouldTranslateButtonsInComponent:boolean = true): Promise<void> {

    return new Promise<void>((resolve, reject) => {
      ConfirmOrConfirmCancelDialog.setDialog({
        header: header,
        message: message,
        displayType: displayType,
        onOK: () => {
          ConfirmOrConfirmCancelDialog.clearAndClose();
          resolve()
        },
        onCancel: () => {
          ConfirmOrConfirmCancelDialog.clearAndClose();
          reject();
        },
        shouldTranslateButtons:shouldTranslateButtonsInComponent
      })
      ConfirmOrConfirmCancelDialog.animateToOpenstate(true);
    })
  }

  private static animateToOpenstate(newState:boolean) {
    gsap.to(".popup_twobutton.modal-wrapper", newState ? {opacity: 1, zIndex: 999999, duration: 0.4}
        : {opacity: 0, zIndex: -1, duration: 0.4})
  }
}

class OKorOKCancelDialog extends ConfirmOrConfirmCancelDialog {
  public static displayOKOKCancelDialog(header:string, message:string ,dialogType:"ConfirmCancel" | "Confirm") {
    confirmTextID.value = "konf_OK";
    canceltextID.value = "konf_Cancel";
    return ConfirmOrConfirmCancelDialog.displayOKOKCancelDialog(header, message, dialogType);
  }
}



class YesNoDialog extends ConfirmOrConfirmCancelDialog {
  public static displayOKOKCancelDialog(header:string, message:string, dialogType:"ConfirmCancel" | "Confirm") {
    confirmTextID.value = "konf_yes";
    canceltextID.value = "konf_no";
    return ConfirmOrConfirmCancelDialog.displayOKOKCancelDialog(header, message, dialogType);
  }
}

class ButtonDialog extends ConfirmOrConfirmCancelDialog {

  private static setButtonTexts(okID:string, cancelID:string){
    confirmTextID.value = okID;
    canceltextID.value = cancelID;
  }

  public static displayTwoButtonDialog(header:string, message:string, okID:string, cancelID:string, shouldTranslateButtonsInComponent:boolean = true) {
    this.setButtonTexts(okID, cancelID);
    return ConfirmOrConfirmCancelDialog.displayOKOKCancelDialog(header, message, "ConfirmCancel", shouldTranslateButtonsInComponent);
  }

  public static displayOneButtonDialog(header:string, message:string, okID:string, cancelID:string) {
    this.setButtonTexts(okID, cancelID);
    return ConfirmOrConfirmCancelDialog.displayOKOKCancelDialog(header, message, "Confirm");
  }
}



export function displayConfirmationDialog(header:string, message:string): Promise<void> {
  return OKorOKCancelDialog.displayOKOKCancelDialog(header, message, "ConfirmCancel")
}

export function displayYesNoDialog(header:string, message:string):Promise<void> {
  return YesNoDialog.displayOKOKCancelDialog(header, message, "ConfirmCancel");
}

export function displayAcknowledgePopup(header:string, message:string): Promise<void> {
  return OKorOKCancelDialog.displayOKOKCancelDialog(header, message, "Confirm");
}

export function displayTwoButtonDialog(header:string, message:string, buttonText1:string, buttonText2:string, translateButtonsInComponent:boolean = true):Promise<void> {{
  return ButtonDialog.displayTwoButtonDialog(header, message, buttonText1, buttonText2, translateButtonsInComponent);
}}

</script>

<style scoped lang="scss">
.modal-wrapper {
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
  opacity: 0;

  .km__popup--heading {
    margin-bottom: 20px;
  }
}

.popup_twobutton.km__popup {
  height: 350px;

  .km__button--standard {
    padding: 0 10px;
  }

  .km__popup--content {
    p {
      font-size: 13px;
      line-height: 1.45em;
      margin-bottom: 15px;
    }
  }
}
</style>
