<template>
  <div class="shop__popup--wrapper">
    <div class="open-popup-editshop shop__popup">
      <button class="km__button--close" type="button" name="close" @click="$emit('close_popup')"></button>
      <div class="km__popup--content shop__logo">
        <div id="logo-upload-container">
          <input id="shopLogoUpload" class="btn-upload" name="shopLogoUpload" type="file" @change="uploadLogo($event, ()=>$emit('close_popup'))">
          <label class="km__button--standard blue" for="shopLogoUpload">{{$t("logoHochladen")}}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import {type ShopLogoUploadResponse} from "@k/js/databaseInterfaceClasses";
import {useShopInfos} from "~/store/shopInfosStore";

function uploadLogo(e:Event, close:()=>void) {
  const shopInfos = useShopInfos();
  const formData = new FormData();
  formData.append("file", ((e.target as HTMLInputElement).files as FileList)[0]);
  formData.append("shopname", shopInfos.shopname);
  axios.post("/shop_php/uploadShopLogo.php", formData, {
    headers: {'Content-Type': 'multipart/form-data'}
  }).then((response:{data:ShopLogoUploadResponse}) => {
    shopInfos.setShopLogoPath(response.data.ShopLogoURL);
    close();
  });
}

export default {
  name: "PopupEditLogo",
  setup(){
    return{
      uploadLogo:(e:Event, close:()=>void)=>uploadLogo(e, close),
    }
  }
}
</script>

<style scoped>
input {
  display: none;
}
label {
  margin:auto
}
</style>
