<template>
  <div class="shop_module_feedback">
    <div class="message">{{message}}</div>
  </div>
</template>

<script>
import {MessageToDisplayContainer} from "@km/components/popups/MessageToDisplayContainer";
import {ref} from "vue";

export default {
  name: "ModuleShopPopupMessages",
  setup() {
    const message = ref("");
    MessageToDisplayContainer.setOptions(".shop_module_feedback", 3);
    MessageToDisplayContainer.init(message);
    return {
      message
    }
  }
}
</script>

<style scoped lang="scss">
.shop_module_feedback {
  font-size: 11px;
  line-height: 1.45em;
  width: 180px;
  height: auto;
  padding: 20px;
  background: rgb(89, 204, 170);
  box-shadow: 1px 1px 8px $color-black;
  opacity: 0;
  z-index:-1;
  position: fixed;
  top: 50%;
  left: 50%;
}

</style>
