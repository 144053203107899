<template>
  <div class="shop__payment">
    <div class="payment--choice">
      <input type="radio" name="zv" id="zv_bankkonto" value="zv_bankkonto" v-model="zahlungsverbindung"/>
      <label for="zv_bankkonto">{{ $t("shop_bankkonto") }}</label>
      <input type="radio" name="zv" id="zv_paypal" value="zv_paypal" v-model="zahlungsverbindung"/>
      <label for="zv_paypal">{{ $t("shop_paypal") }}</label>
    </div>
    <div v-if="zahlungsverbindungPayPal">
      <label class="km__label--text" for="ppKontoinhaber">{{ $t("shop_Kontoinhaber") }}</label>
      <input class="km__input--standard" type="text" v-model="kontoinhaber" id="ppKontoinhaber">
      <label class="km__label--text" for="ppEmail">{{ $t("konf_Email") }}</label>
      <input class="km__input--standard" type="text" v-model="email" id="ppEmail">
    </div>
    <div v-if="zahlungsverbindungBank">
      <label class="km__label--text" for="bankKontoinhaber">{{ $t("shop_Kontoinhaber") }}</label>
      <input class="km__input--standard" type="text" v-model="kontoinhaber" id="bankKontoinhaber">
      <label class="km__label--text" for="iban">{{ $t("shop_iban") }}</label>
      <input class="km__input--standard" type="text" v-model="iban" id="iban">
      <label class="km__label--text" for="nameDerBank">{{ $t("shop_nameDerBank") }}</label>
      <input class="km__input--standard" type="text" v-model="nameDerBank" id="nameDerBank">
      <label class="km__label--text" for="bic">{{ $t("shop_bic") }}</label>
      <input class="km__input--standard" type="text" v-model="bic" id="bic">
    </div>
    <div v-if="saveSuccessful">
      <label class="km__label--saveSuccess">{{ $t('shop_AenderungenGespeichert') }}</label>
    </div>
    <div v-if="!ibanValid">
      <label class="km__label--saveError">{{$t('shop_IBANNotValid')}}</label>
    </div>
  </div>
  <div class="km__popup--buttons">
    <button class="km__button--standard" @click="$emit('close_popup')">{{ $t("konf_Close") }}</button>
    <button class="km__button--standard blue" @click="$emit('save')">{{ $t("konf_Save") }}</button>
  </div>
</template>

<script lang="ts">
import {computed} from "vue";
import {useShopStore} from "@ur/components/store/UserShopStore";

export default {
  name: "EditAccountZahlungsverbindung",
  emits: ["close_popup", "save"],
  setup() {

    const userStore = useShopStore();
    userStore.loadIfNotAlreadyLoaded();
    const zahlungsverbindung = computed({
      get() {
        return userStore.getZahlungsverbindung
      },
      set(newValue) {
        userStore.SET_USERSHOP_STATUS("");
        userStore.SET_USERSHOP_ZAHLUNGSVERBINDUNG(newValue as string)
      }
    });

    const kontoinhaber = computed({
      get() {
        return userStore.getZahlungsverbindungKontoinhaber
      },
      set(newValue) {
        userStore.SET_USERSHOP_STATUS("");
        userStore.SET_USERSHOP_ZVKONOINHABER(newValue as string)
      }
    });

    const email = computed({
      get() {
        return userStore.getZahlungsverbindungEmail
      },
      set(newValue) {
        userStore.SET_USERSHOP_STATUS("");
        userStore.SET_USERSHOP_ZVEMAIL(newValue)
      }
    });

    const iban = computed({
      get() {
        return userStore.getZahlungsverbindungIBAN
      },
      set(newValue) {
        userStore.SET_USERSHOP_STATUS("");
        userStore.SET_USERSHOP_ZVIBAN(newValue)
      }
    });

    const bic = computed({
      get() {
        return userStore.getZahlungsverbindungBIC
      },
      set(newValue) {
        userStore.SET_USERSHOP_STATUS("");
        userStore.SET_USERSHOP_ZVBIC(newValue)
      }
    });

    const nameDerBank = computed({
      get() {
        return userStore.getZahlungsverbindungNameDerBank
      },
      set(newValue) {
        userStore.SET_USERSHOP_STATUS("");
        userStore.SET_USERSHOP_ZVNAMEDERBANK(newValue)
      }
    });

    return {
      zahlungsverbindung,
      kontoinhaber,
      email,
      iban,
      bic,
      nameDerBank,
      ibanValid: computed(()=>userStore.getIBANValid),
      zahlungsverbindungPayPal: computed(() => userStore.getZahlungsverbindung === "zv_paypal"),
      zahlungsverbindungBank: computed(() => userStore.getZahlungsverbindung === "zv_bankkonto"),
      saveSuccessful: computed(() => userStore.getUserShopStatus === "success"),
    }
  }
}
</script>

<style lang="scss" scoped>
.shop__payment {
  background-color: $color-stage-bg;
  padding: 20px;
  height: 500px;

  .km__input--standard {
    background-color: #F6F6F6;
  }

  .km__label--text {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 4px;
    font-weight: 500;
  }

  .km__label--saveSuccess {
    font-size: 13px;
    margin-bottom: 4px;
    font-weight: 500;
    color: green;
  }

  .km__label--saveError {
    font-size: 13px;
    margin-bottom: 5px;
    font-weight: 500;
    color: red;
    display: block;
    padding-top: 3px;
  }
}

.payment--choice {
  margin-bottom: 20px;

  input {
    width: 15px;
    height: 15px;
    padding: 0;
    margin: 0;
  }

  label {
    position: relative;
    top: -3px;
    margin-left: 5px;
    margin-right: 25px;
  }
}
</style>
