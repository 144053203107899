<template>
    <div class="shop-notavailable">
        <div class="notavailable-wrapper">
            <div class="owayo-info">
                <span>{{$t("shop_owayoTeamshop")}}</span>
            </div>
            <div class="notavailable-404">
                <p>
                    <span class="site-404">404</span>
                    <span class="not-exist">{{$t("shop_not_existing")}}</span>
                </p>
            </div>
        </div>
    </div>


</template>

<script>
import ShopFooter from "~/components/ShopFooter.vue";

export default {
  name: "ShopNotAvailable",
  components: {
    ShopFooter
  },
}
</script>

<style lang="scss" scoped>
.shop-notavailable {
  width: $shop-content;
  margin: 0 auto;
  max-width: 100%;

  .notavailable-wrapper {
    padding: 60px 20px;
    text-align: center;
  }

  .owayo-info {
    width: 100%;
    text-align: center;
    color: $color-blue;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 16px;
  }

  .notavailable-404 {
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .site-404 {
      display: block;
      font-size: 72px;
      line-height: 74px;
      font-weight: 700;
      margin-bottom: 24px;
    }

    .not-exist {
      display: block;
      max-width: 240px;
    }
  }
}
</style>
