<template>
  <div class="tabs">
    <div class="tabs--tabButtons">
      <button class="tab--link"
              :class="{'active': isActiveTab(tabData.name)}"
              v-for="[identifier, tabData] in tabs.entries()" :key="identifier"
              @click="setActiveTab(identifier)" :ref="el=>{buttons[tabData.name] = el}">
        {{ tabData.name }}
      </button>
    </div>

    <div class="tabs--tab">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, computed, ref } from 'vue';
  import {useTabs} from '../js/tabs';
  export default defineComponent({
    name: 'Tabs',
    props: {
      initialTab:String
    },
    setup (props) {
      const { tabs, setActiveTab, activeTab, activeTabStr } = useTabs();
      const buttons = ref([]);
      onMounted(() => {
        buttons.value[props.initialTab].click();
        })

      function clickAButtonFromParent(btnName){
        buttons.value[btnName].click();
      }

      return {
        tabs,
        setActiveTab,
        clickAButtonFromParent,
        isActiveTab: computed(()=> function(tabName){return activeTabStr.value===tabName}),
        buttons
      }
    },
  })
</script>

<style lang="scss" scoped>
.tabs--tabButtons {
  display: flex;

  .tab--link {
    display: block;
    color: $color-black;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
  }
}

</style>


