<template>
  <div class="shop__popup--wrapper">
    <div class="open-popup-editshop shop__popup">
      <button class="km__button--close" type="button" name="close" @click="checkIfDirty"></button>
      <div class="km__popup--content product_filter">
        <div class="edit-product">
          <h2 class="heading" v-if="product!==null">{{$t("shop_ProduktfilterErstellen")}}</h2>
          <h2 class="heading" v-else>{{$t("shop_ProduktfilterZuweisen")}}</h2>
          <div class="product" v-if="product!==null">
            <div class="product_filter--product">
              <div id="possibleProductPreview" class="possible-product-image">
                <img :src="getPreviewPicURL" alt="" class="fullwidth">
              </div>
              <div class="possible-product-title">
                <input class="km__input--standard" type="text" :value="product.nameImShop" @input="changeName($event.target.value)"  />
                <span class="product-title">{{product.produkt}}</span>
                <span class="product-feature">{{product.featureStr}}</span>
              </div>
            </div>
          </div>
          <div class="filter" :class="{ 'no-checkbox': product === null }">

            <div class="product_filter--filterTags">
              <FilterTreeView :product="product" @close_popup="$emit('close_popup')" @dirty="setFiltersDirty"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useShopItems} from "~/store/shopItemsStore";
import FilterTreeView from "~/components/FilterTreeView.vue";
import {useShopInfos} from "~/store/shopInfosStore";
import {displayYesNoDialog} from "@km/components/popups/ModuleOKOKCancelDialog.vue";
import {useI18n} from "vue-i18n";


export default {
  name: "PopupEditFilter",
  components: {FilterTreeView},
  props: {
    product: Object
  },
  setup(props, context){
    const getPreviewPicURL = computed(()=>props.product.previewPath+props.product.saveID+"_0.png");
    const shopItems= useShopItems();
    const shopInfos = useShopInfos();
    const isDirty = ref(false);
    const i18n = useI18n();

    function checkIfDirty(){
      if(isDirty.value){
        displayYesNoDialog(i18n.t("shop_UpdateVerwerfen"), i18n.t("shop_exitWithoutSaving")).then(()=>{
          context.emit('close_popup');
        }).catch(()=> {})
      }
      else{
        context.emit('close_popup');
      }
    }

    function setFiltersDirty(){
      isDirty.value = true;
    }

    return{
      getPreviewPicURL,
      checkIfDirty,
      setFiltersDirty
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.no-checkbox .tag-checkbox) {
  display: none
}
.heading {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}
.open-popup-editshop {
  width: 600px;
  max-width: 100%;
}

.product_filter {
  overflow-y: auto;
  height: 800px;
  width: 100%;

  @media (max-width: 540px) {
    height: 400px;
  }
}

.product_filter--product{
  display: flex;
}

.product_filter--filterTags{
  width: 100%;
}

.product_filter--filterDropdowns{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.product_filter--newTags{
  width: 50%;
  padding-right: 40px;

  .km__input--standard {
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.possible-product-image {
  width: 100%;
  min-height: 150px;
  padding: 10px;

  @media (max-width: 800px) {
    width: 100%;
  }
}

.possible-product-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .product-title,
  .product-feature {
    display: block;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
}

.km__button--standard{
  margin-top: 0;
  margin-bottom: 20px;
}

.tag-chose-wrapper {
  display: flex;
  margin-bottom: 12px;

  .left {
    width: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .right {
    width: calc(100% - 35px);
  }
}

.heading-new-tag {
  font-size: 17px;
  margin-bottom: 16px;
  font-weight: 500;
}

.tag-chose {
  padding-top: 20px;

  .heading {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
  }
}
.edit-product {
  width: 100%;

  .product {
    width: 100%;
    margin-bottom: 40px;
  }

  .filter {
    width: 100%;

  }
}

.wrapper-add-tag {
  display: flex;
  justify-content: flex-start;
  padding-left: 35px;

  .btn-add-tag {
    width: 140px;
  }
}

</style>