<template>
  <div class="shop__tax--status">
    <!--<h3>{{$t('shop_steuerStatus')}}</h3>-->
    <p>
      <label class="km__label--text">{{$t('shop_einnahmenInfo')}}</label>
      <input type="radio" name="stst" id="stst_ohneRegistrierung" value="stst_ohneRegistrierung" v-model="steuerstatus" :disabled="isFirmaOderPerson"/>
      <label for="stst_ohneRegistrierung">{{ $t("shop_ststOhneSteuerlicheRegistrierung") }}</label><br>
      <input type="radio" name="stst" id="stst_kleinunternehmer" value="stst_kleinunternehmer" v-model="steuerstatus" :disabled="isFirmaOderPerson===false || isDE===false"/>
      <label for="stst_kleinunternehmer">{{ $t("shop_ststKleinunternehmer") }}</label><br>
      <input type="radio" name="stst" id="stst_de19" value="stst_de19" v-model="steuerstatus" :disabled="isFirmaOderPerson===false || isDE===false"/>
      <label for="stst_de19">{{ $t("shop_ststDe19") }}</label><br>
      <input type="radio" name="stst" id="stst_de7" value="stst_de7" v-model="steuerstatus" :disabled="isFirmaOderPerson===false || isDE===false"/>
      <label for="stst_de7">{{ $t("shop_ststDe7") }}</label><br>
      <input type="radio" name="stst" id="stst_eu" value="stst_eu" v-model="steuerstatus" :disabled="isFirmaOderPerson===false || isDE || isEU===false"/>
      <label for="stst_eu">{{ $t("shop_ststEu") }}</label><br>
      <input type="radio" name="stst" id="stst_nichtEU" value="stst_nichtEU" v-model="steuerstatus" :disabled="isFirmaOderPerson===false || isDE || isEU"/>
      <label for="stst_nichtEU">{{ $t("shop_ststNichtEu") }}</label>
    </p>
    <div v-if="steuerStatusOhneRegistrierung">
      <label class="km__label--text">{{$t("shop_ststOhneSteuerlicheRegistrierung_desc")}}</label>
    </div>
    <div v-if="steuerStatusKleinunternehmer">
      <label class="km__label--text">{{$t("shop_ststKleinunternehmer_desc")}}</label>
    </div>
    <div v-if="steuerStatusDE19">
      <label class="km__label--text" >{{$t("shop_ststDe19_desc")}}</label>
      <EditAccountSteuerstatusNachweisDE/>
    </div>
    <div v-if="steuerStatusDE7">
      <label class="km__label--text" >{{$t("shop_ststDe7_desc")}}</label>
      <EditAccountSteuerstatusNachweisDE/>
    </div>
    <div v-if="steuerStatusEU">
      <label class="km__label--text" >{{$t("shop_ststEu_desc")}}</label>
      <label class="km__label--text" >{{$t("shop_ReverseCharge")}}</label>get
      <label class="km__label--text" >{{$t("shop_UmsatzsteuerEU")}}</label>
      <input class="km__input--standard" type="text" v-model="shopUStID" id="shopVat">
    </div>
    <div v-if="steuerStatusNichtEU">
      <label class="km__label--text" >{{$t("shop_ststNichtEu_desc")}}</label>
      <label class="km__label--text" >{{$t("shop_ReverseChargeNonEU")}}</label>
    </div>
    <div v-if="saveSuccessful">
      <label class="km__label--saveSuccess">{{$t('shop_AenderungenGespeichert')}}</label>
    </div>
  </div>
  <div class="km__popup--buttons">
      <button class="km__button--standard" @click="$emit('close_popup')">{{ $t("konf_Close") }}</button>
      <button class="km__button--standard blue" @click="$emit('save')">{{ $t("konf_Save") }}</button>
  </div>
</template>

<script lang="ts">
import {computed} from "vue";
import EditAccountSteuerstatusNachweisDE from "~/components/EditAccountSteuerstatusNachweisDE.vue";

import {useShopStore} from "@ur/components/store/UserShopStore";
import type {ShopAdresse} from "@global/js/UserShop";



export default {
  name: "EditAccountSteuerstatus",
  components: {EditAccountSteuerstatusNachweisDE},
  emits: ["close_popup", "save"],
  setup(){
    const userShop = useShopStore();
    userShop.loadIfNotAlreadyLoaded();
    const steuerstatus = computed({
        get(){return userShop.getSteuerstatus},
        set(newValue){
          userShop.SET_USERSHOP_STATUS("");
          userShop.SET_USERSHOP_STEUERSTATUS(newValue as string)
        }
    });

    const shopUStID = computed({
      get(){return userShop.getShopUStID},
      set(newValue){
        userShop.SET_USERSHOP_STATUS("");
        userShop.SET_USERSHOP_USTID(newValue as string)
      }
    }
    );



   return{
     steuerstatus,
     shopUStID,
     steuerStatusOhneRegistrierung: computed(()=> userShop.getSteuerstatus==="stst_ohneRegistrierung"),
     steuerStatusKleinunternehmer: computed(()=> userShop.getSteuerstatus==="stst_kleinunternehmer"),
     steuerStatusDE19: computed(()=> userShop.getSteuerstatus==="stst_de19"),
     steuerStatusDE7: computed(()=> userShop.getSteuerstatus==="stst_de7"),
     steuerStatusEU: computed(()=> userShop.getSteuerstatus==="stst_eu"),
     steuerStatusNichtEU: computed(()=> userShop.getSteuerstatus==="stst_nichtEU"),
     isFirmaOderPerson: computed(()=>userShop.getIsFirmaOrPerson),
     isDE: computed(()=>(userShop.getUserShopAdresse as ShopAdresse).iso3166==='DE'),
     isEU: computed(()=>(userShop.getUserShopAdresse as ShopAdresse).isEU),
     saveSuccessful: computed(()=>userShop.getUserShopStatus==="success")
   }
  }
}
</script>

<style lang="scss" scoped>
.shop__tax--status {
  background-color: $color-stage-bg;
  padding: 20px;

  p {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  .km__input--standard {
    background-color: #F6F6F6;
  }

  .km__label--text {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 4px;
    font-weight: 500;
  }

  .km__label--saveSuccess {
    font-size: 13px;
    margin-bottom: 4px;
    font-weight: 500;
    color: green;
  }

}
</style>
