<script setup lang="ts">
import {useShopInfos} from "~/store/shopInfosStore";
import {loadInitialShopInfo} from "~/init/loadInitialData";

const error = useError();
const link = window?.location.origin;

if(error.value.message!=='shop not found'){
  await loadInitialShopInfo();
}

const shopInfoStore = useShopInfos();
const shopObj = computed(() => shopInfoStore.shopInfo);

</script>

<template>

    <div class="prose">
      <template v-if="error.statusCode === 404">
        <div class="team-wrapper" v-if="error.message === 'Item not found'">
          <ShopHeader
              :shopinfo="shopObj"
              :isOwner="false"
          />
          <div>
            <FourOFour/>
          </div>
          <ShopFooter :isOwner="false"/>
        </div>
        <div class="team-wrapper" v-else-if="error.message === 'Item not available'">
          <ShopHeader
              :shopinfo="shopObj"
              :isOwner="false"
          />
          <div>
            <FourOFour/>
          </div>
          <ShopFooter :isOwner="false"/>
        </div>
        <div v-else>
          <h1>404 - Page not found</h1>
          <p>Sorry, that page doesn't exist.</p>

          <p>
            <ClientOnly>
              <a :href="link">Home
              </a>
            </ClientOnly>
          </p>

        </div>
      </template>
      <template v-else>
        <h1>Sorry!</h1>
        <p>
          <strong>{{ error.message }}</strong>
        </p>
        <p>It looks like something broke.</p>
        <p>Sorry about that.</p>

        <p>
          <ClientOnly>
            <a :href="link">Home
            </a>
          </ClientOnly>
        </p>
      </template>
    </div>

</template>

<style scoped lang="scss">
h1 {
  text-align: center;
  font-size: 4rem;
}
p {
  text-align: center;
}
</style>
