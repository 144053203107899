<template>
  <div>
    <div class="shop__theme--auswahl">
      <input type="radio"
             id="{{theme.theme}}"
             :checked="isSelectedTheme"
             name="selectTheme"
             v-bind:value="{theme: theme.theme}"
             v-on:change="$emit('changeSelectedTheme', {theme: theme.theme, image: theme.backgroundImage})"
      />
      <div class="shop__theme--title">{{ theme.theme }}</div>
    </div>

    <img class="shop__theme--image" :src="image" :alt="theme.theme"/>
  </div>
</template>

<script lang="ts">
import {computed, type PropType} from "vue";
import {useShopInfos} from "~/store/shopInfosStore";
import {insertCloudflareAutoformatIntoURL} from "@k/global/globalUtils";

export default {
  name: "PopupEditThemeOption",
  props: {
    theme: Object as PropType<{ theme: string, backgroundImage: string }>
  },
  setup: function (props) {
    const shopInfos = useShopInfos();
    return {
      isSelectedTheme: computed(() => shopInfos.theme === props.theme.theme),
      image: computed(() => props.theme.backgroundImage.includes("owayo-cdn.com") ? insertCloudflareAutoformatIntoURL(props.theme.backgroundImage) : props.theme.backgroundImage.replace(".jpg", ".webp"))
    }
  }
}
</script>

<style lang="scss" scoped>
.shop__theme--auswahl {
  width: 30px;
  display: flex;

  input {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
  }
}

.shop__theme--title {
  display: inline-block;
  font-weight: 500;
  padding-left: 8px;
  padding-top: 2px;
}

.shop__theme--image {
  width: 100%;
  margin-top: 10px;
}
</style>
