<template>
  <div class="filter-tree-heading">
    <div class="cat-heading">
      <span v-if="!isEditingCat[index]" class="category-label">{{ item.category }}</span>
      <input
          v-else
          v-model="item.category"
          @keyup.enter="updateCat(index)"
          @blur="updateCat(index)"
          ref="input"
          :placeholder="item.category"
          class="category-input"
      />
    </div>
    <div class="btn-wrapper-cat">
      <button
          @click="editCat(index)"
          class="category-btn"
          :class="isEditingCat[index] ? 'save' : 'edit'"
      >
        {{ isEditingCat[index] ? '' : '' }}
      </button>
      <button
          @click="deleteCategory(index)"
          class="category-btn delete"
      >
      </button>
    </div>
  </div>
  <div v-for="(tag, index) in item.tags" :key="index" class="filter-tree-tag">
    <div class="tag-wrapper">
      <input class="tag-input tag-checkbox" type="checkbox" id="tag.name" value="tag.name" :checked="tag.checked" @change="toggleChecked(tag)" />
      <div class="tag-edit-wrapper">
        <span v-if="!isEditingTag[index]" class="tag-label">{{ tag.name }}</span>
        <input
            v-else
            v-model="tag.name"
            @keyup.enter="updateTag(index)"
            @blur="updateTag(index)"
            ref="input"
            :placeholder="tag.name"
            class="tag-input"
        />
        <div class="btn-wrapper-tag">
          <button
              @click="editTag(index)"
              class="tag-btn"
              :class="isEditingTag[index] ? 'save' : 'edit'"
          >
            {{ isEditingTag[index] ? '' : '' }}
          </button>
          <button
              @click="deleteTag(index)"
              class="tag-btn delete"
          >
          </button>
        </div>
      </div>
    </div>
  </div>
  <input class="new-tag" type="text" :placeholder="$t('shop_newTagPlaceholder')" @keyup.enter="addNewTag" v-model="newTag">
  <button v-if="newTag!==''" @click="addNewTag" class="btn-new-tag">{{$t("shop_add")}}</button>
</template>

<script lang="ts">
import {nextTick, reactive} from "vue";
import {type FilterTag, useShopItems} from "~/store/shopItemsStore";
import {useShopInfos} from "~/store/shopInfosStore";
import {displayYesNoDialog} from "@km/components/popups/ModuleOKOKCancelDialog.vue";
import {useI18n} from "vue-i18n";

export default {
  name: "FilterTreeViewCategory",
  props: {
    item: {
      type: Object as PropType<FilterTag>,
      required: true
    }
  },
  setup(props, context) {
    const shopItems= useShopItems();
    const shopInfos = useShopInfos();
    const newTag = ref('');
    const i18n = useI18n();

    // Reaktives Objekt für den Bearbeitungszustand
    const isEditingCat = reactive({});
    const isEditingTag = reactive({});

    // Funktion zum Bearbeiten eines Eintrags
    const editCat = (index) => {
      if (isEditingCat[index]) {
        // Falls bereits bearbeitet wird, Änderungen speichern und Bearbeitung beenden
        updateCat(index);
      } else {
        // Anderen Einträgen den Bearbeitungsmodus entziehen
        for (let key in isEditingCat) {
          isEditingCat[key] = false;
        }
        // Bearbeitungsmodus aktivieren
        isEditingCat[index] = true;
        nextTick(() => {
          // Fokus auf das Eingabefeld setzen
          document.querySelectorAll('input')[index].focus();
        });
      }
    }

    const editTag = (index) => {
      if (isEditingTag[index]) {
        // Falls bereits bearbeitet wird, Änderungen speichern und Bearbeitung beenden
        updateTag(index);
      } else {
        // Anderen Einträgen den Bearbeitungsmodus entziehen
        for (let key in isEditingTag) {
          isEditingCat[key] = false;
        }
        // Bearbeitungsmodus aktivieren
        isEditingTag[index] = true;
        nextTick(() => {
          // Fokus auf das Eingabefeld setzen
          document.querySelectorAll('input')[index].focus();
        });
      }
    }

    const deleteTag = (index) => {
      const currentTag = props.item.tags[index];
      if(isFilterTagUsed(props.item.id, currentTag.id)){
        displayYesNoDialog(i18n.t("shop_DeleteFilterHeader"), i18n.t("shop_DeleteFilterText")).then(()=>{
          shopItems.deleteTag({shopname: shopInfos.shopname, categoryID:props.item.id, tagID:currentTag.id});
        }).catch(()=> {})
      }
      else{
        shopItems.deleteTag({shopname: shopInfos.shopname, categoryID:props.item.id, tagID:currentTag.id});
      }
    }

    const deleteCategory = (index) => {
      if(isFilterCategoryUsed(props.item.id)){
        displayYesNoDialog(i18n.t("shop_DeleteFilterHeader"), i18n.t("shop_DeleteFilterText")).then(()=>{
          shopItems.deleteCategory({shopname: shopInfos.shopname, categoryID: props.item.id});
        }).catch(()=> {})
      }
      else {
        shopItems.deleteCategory({shopname: shopInfos.shopname, categoryID: props.item.id});
      }
    };

    const updateCat = (index) => {
      isEditingCat[index] = false;
      shopItems.updateCategory({shopname: shopInfos.shopname, categoryID:props.item.id, category: props.item.category});
    };

    const updateTag = (index) => {
      isEditingTag[index] = false;
      const currentTag = props.item.tags[index];
      shopItems.updateTag({shopname: shopInfos.shopname, categoryID:props.item.id, tagID:currentTag.id, tag:currentTag.name});
    };

    function isFilterTagUsed(categoryID, tagID) {
      return shopItems.allShopItems.shopitems.filter((item) => {
          const itemCategoryGroup = item.tags.find(categoryGroup => categoryGroup.id === categoryID);
          if (!itemCategoryGroup) {
              return false;
          }
          return itemCategoryGroup.tags.find(t => t.id === tagID);
      }).length > 0;
    }

    function isFilterCategoryUsed(categoryID){
      return shopItems.allShopItems.shopitems.filter((item) => item.tags.find(categoryGroup => categoryGroup.id === categoryID)).length > 0;
    }

    function toggleChecked(tag){
      tag.checked = !tag.checked;
      context.emit('dirty');
    }

    function addNewTag(){
      if (newTag.value.trim()) {
        shopItems.addTag({shopname: shopInfos.shopname, categoryID:props.item.id, tag: newTag.value});
        newTag.value = '';
      }
    }

    return{
      toggleChecked,
      isEditingCat,
      isEditingTag,
      editCat,
      editTag,
      updateCat,
      updateTag,
      newTag,
      addNewTag,
      deleteTag,
      deleteCategory
    }

  }
}
</script>

<style lang="scss" scoped>
.filter-tree-heading {
  background-color: #EEEEEE;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 42px;
  position: relative;

  .cat-heading {
    display: flex;
  }
}

.tag-edit-wrapper {
  display: flex;
}

.tag-wrapper {
  display: flex;
}

.category-label,
.tag-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  height: 35px;
  margin-left: 16px;
  margin-right: 10px;
}

.tag-label {
  font-size: 14px;
  font-weight: 400;
}

.category-input,
.tag-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  height: 24px;
  margin-right: 0px;
  margin-left: 12px;
  padding-left: 10px;
  border: 1px solid #C0BEBF;
}

.category-btn,
.tag-btn {
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  &.edit {
    background-size: 24px auto;
    background-color: #EEEEEE;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMDAgMTAwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojMzMzMzMzO30NCjwvc3R5bGU+DQo8Zz4NCgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjMuOSw3OS4xaDQ2LjRjMS42LDAsMi45LTEuMywyLjktMi45VjU0LjNjMC0xLjYtMS4zLTIuOS0yLjktMi45Yy0xLjYsMC0yLjksMS4zLTIuOSwyLjl2MTguOUgyNi44VjMyLjZINDMNCgkJYzEuNiwwLDIuOS0xLjMsMi45LTIuOWMwLTEuNi0xLjMtMi45LTIuOS0yLjlIMjMuOWMtMS42LDAtMi45LDEuMy0yLjksMi45djQ2LjRDMjEsNzcuOCwyMi4zLDc5LjEsMjMuOSw3OS4xeiIvPg0KCTxnPg0KCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNjMuMSwyMy42TDQxLjYsNDUuMmMtMC4zLDAuMy0wLjYsMC43LTAuNywxLjJsLTMuMSwxMi43Yy0wLjMsMC45LDAsMS45LDAuNywyLjVjMC41LDAuNSwxLjIsMC44LDEuOSwwLjgNCgkJCWwwLjYtMC4xbDEyLjctMy4yYzAuNC0wLjEsMC45LTAuMywxLjItMC43bDIxLjUtMjEuNmMzLjYtMy43LDMuNS05LjYtMC4xLTEzLjJDNzIuNywyMCw2Ni43LDIwLDYzLjEsMjMuNnogTTQ0LDU2LjFsMS45LTcuNmwwLDANCgkJCWwxNi45LTE3bDUuNyw1LjdsLTAuMiwwLjJMNTEuNiw1NC4xbC0wLjEsMEw0NCw1Ni4xeiBNNzIuMywzMy40bC01LjctNS43bDAuMy0wLjNjMS41LTEuNiw0LTEuNiw1LjYtMC4xYzAuMSwwLDAuMSwwLjEsMC4xLDAuMQ0KCQkJYzEuNiwxLjYsMS42LDQuMSwwLDUuN0w3Mi4zLDMzLjR6Ii8+DQoJPC9nPg0KPC9nPg0KPC9zdmc+DQo=");
   }

  &.delete {
    background-size: 15px;
    background-color: #EEEEEE;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIyIDIyIj4NCiAgPGcgaWQ9IktvbXBvbmVudGVfNTVfMTQiIGRhdGEtbmFtZT0iS29tcG9uZW50ZSA1NSDigJMgMTQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDApIj4NCiAgICA8cmVjdCBpZD0iUmVjaHRlY2tfNDc5IiBkYXRhLW5hbWU9IlJlY2h0ZWNrIDQ3OSIgd2lkdGg9IjIuMTI3IiBoZWlnaHQ9IjkuMjk0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMi4xMjIgOC4wNTIpIiBmaWxsPSIjMjQyNDI0Ii8+DQogICAgPHJlY3QgaWQ9IlJlY2h0ZWNrXzQ4MCIgZGF0YS1uYW1lPSJSZWNodGVjayA0ODAiIHdpZHRoPSIyLjEyNyIgaGVpZ2h0PSI5LjI5NCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNy43NTEgOC4wNTIpIiBmaWxsPSIjMjQyNDI0Ii8+DQogICAgPHBhdGggaWQ9IlBmYWRfMzEyIiBkYXRhLW5hbWU9IlBmYWQgMzEyIiBkPSJNMTc4Ljg5LDI0NS40MTVoLTYuOTE0YTQuMTU2LDQuMTU2LDAsMCwwLTguMTczLDBIMTU2Ljg5djIuMTI4aDIuODc2djE2LjQ3M2gxNi4yNDZWMjQ3LjU0M2gyLjg3OFptLTUuMTI3LDE2LjM1SDE2Mi4wMTZ2LTE0LjFoMTEuNzQ3Wm0tNS44NzMtMTcuNjIyYTIuMDM4LDIuMDM4LDAsMCwxLDEuODg0LDEuMjcyaC0zLjc2OUEyLjAzOCwyLjAzOCwwLDAsMSwxNjcuODksMjQ0LjE0M1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNTYuODkgLTI0Mi4wMTYpIiBmaWxsPSIjMjQyNDI0Ii8+DQogIDwvZz4NCjwvc3ZnPg0K');
  }

  &.save {
    background-size: 18px auto;
     background-color: #EEEEEE;
     background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSI+PHBhdGggZD0iTTE3LjQxMjkgOC45Mzg1N0wxMC41IDE2LjQ3OTlMNi41ODcxMiAxMi4yMTEzTDguMDYxNDMgMTAuODU5OEwxMC41IDEzLjUyMDFMMTUuOTM4NiA3LjU4NzEyTDE3LjQxMjkgOC45Mzg1N1oiIGZpbGw9ImJsYWNrIi8+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xIDEyQzEgNS45MjQ4NyA1LjkyNDg3IDEgMTIgMUMxOC4wNzUxIDEgMjMgNS45MjQ4NyAyMyAxMkMyMyAxOC4wNzUxIDE4LjA3NTEgMjMgMTIgMjNDNS45MjQ4NyAyMyAxIDE4LjA3NTEgMSAxMlpNMTIgM0M3LjAyOTQ0IDMgMyA3LjAyOTQ0IDMgMTJDMyAxNi45NzA2IDcuMDI5NDQgMjEgMTIgMjFDMTYuOTcwNiAyMSAyMSAxNi45NzA2IDIxIDEyQzIxIDcuMDI5NDQgMTYuOTcwNiAzIDEyIDNaIiBmaWxsPSJibGFjayIvPjwvc3ZnPg==");
   }
}

.tag-btn {
  &.edit {
     background-color: white;
   }

  &.delete {
    background-color: white;
  }

  &.save {
     background-color: white;
  }
}

.btn-wrapper-cat,
.btn-wrapper-tag {
  position: absolute;
  right: 0;
  top: 1px;
  width: 80px;
  height: 40px;
  display: flex;
}

.new-tag {
  margin-left: 40px;
  margin-bottom: 20px;
  margin-top: 12px;
  font-size: 14px;
  height: 24px;
  padding-left: 10px;
  border: 1px solid #C0BEBF;
  width: 300px;
}

.new-category {
  font-size: 14px;
  height: 24px;
  margin-right: 0px;
  margin-left: 12px;
  padding-left: 10px;
  border: 1px solid #C0BEBF;
}

.filter-tree-tag {
  position: relative;
  padding-left: 0px;
  border-bottom: 1px solid #CCCCCC;
  height: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tag-tree {
  .tree-cat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 35px;
    padding: 6px 25px 5px 10px;
    background-color: #EEEEEE;
  }

  .tree-tag {
    display: flex;
    width: 100%;
    height: 30px;
    background-color: white;
    padding-left: 20px;

    .tree-input {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .tree-label {
      padding-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.tag-tree-wrapper {
  padding-top: 32px;
}

.btn-new-tag {
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  background: $color-blue;
  color: $color-white;
  border: none;
  font-size: 14px;
}
</style>