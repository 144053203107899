<template>
  <div class="nachweis">
    <label class="km__label--text" for="shopUStID">{{$t("shop_NachweisUSt")}}</label>
    <input class="km__input--standard" type="text" v-model="shopUStID" id="shopUStID">
    <label class="km__label--text">{{$t("shop_NachweisFormular")}}</label>
    <button class="km__button--link" @click="downloadFormular">{{$t("konf_download")}}</button>
  </div>
</template>

<script lang="ts">
import {computed} from "vue";
import {useShopStore} from "@ur/components/store/UserShopStore";

export default {
  name: "EditAccountSteuerstatusNachweisDE",
  setup(){

    const userStore = useShopStore()
    userStore.loadIfNotAlreadyLoaded();
    const shopUStID = computed({
      get(){return userStore.getShopUStID},
      set(newValue){userStore.SET_USERSHOP_USTID(newValue as string)
      }
    });

    function downloadFormular(){
      window.open('https://static.owayo-cdn.com/vorlagen/Bestaetigung_Umsatzsteuerausweis_Deutschland.pdf');
    }

    return{
      shopUStID,
      downloadFormular
    }
  }
}
</script>

<style  lang="scss" scoped>
.nachweis {
  padding-top: 25px;

  p {
    font-size: 13px;
    line-height: 20px;
  }

  .km__input--standard {
    background-color: #F6F6F6;
    margin-bottom: 26px;
  }

  .km__label--text {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 4px;
    font-weight: 500;
  }

  .km__button--link {
    display: inline-block;
    width: auto;
    margin-top: 5px;
    border-bottom: 1px solid $color-blue;
  }
}
</style>
