<template>
  <div v-if="isActive"  class="tab" >
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent, toRef, reactive, computed } from 'vue';
  import {useTab} from '../js/tabs';

  export default defineComponent({
    name: 'Tab',
    props: {
      name: {
        type: String,
        required: true
      }
    },
    emits:["active_tab"],
    setup (props, context) {
      const name = toRef(props, 'name')
      const tabData = reactive({ name })

      const useTabVar = computed(()=>useTab(tabData));

      return {
        isActive:useTabVar.value.isActive
      }
    }
  })
</script>

<style scoped>

</style>

